<template>

    <div class="form-box">
        <el-form ref="form" :model="form" label-width="160px">

            <el-form-item :label="param.attrName" v-for="param in params" :key="param.id">
                <div v-if="param.isOnly===1">
                    <el-checkbox :label="item" v-for="(item ,idx) in param.availableVal" :key="item"
                                 v-model="param.availableVals[idx]"
                                 @change="_checkChange(item,param,param.availableVals[idx])"></el-checkbox>
                </div>
                <!--单选列表-->
                <el-radio-group v-for="item in param.availableVal" :key="item" v-model="param.availableVals" v-else>
                    <el-radio :label="item">
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="_onNextStep">{{stepTitle}}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import api from '../api/api';
    import {ElMessage} from 'element-plus';

    export default {
        name: 'ClothingParamEdit',
        props: {
            productId: {
                type: Number,
                default: 2
            },
            stepTitle: {
                type: String,
                default: '下一步'
            },
            isEditStatus: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                step: 2,
                params: [],
                isPrepare: true,
                singleValParam: [],
                multipleValParam: [],
                form: []
            };
        },
        watch: {
            productId: function (newVal, oldVal) {
                console.log("productId:", newVal, oldVal)
                this._getClothParam(newVal);
            }
        },
        mounted() {
            this._getClothParams();
            console.log(this.stepTitle)

        },
        methods: {
            _onNextStep() {
                this._checkDataBeforeUpload();
                //属性Id
                if (this.isPrepare) {
                    api.post("/clothing/attr/vals", this.form)
                        .then(res => {
                            console.log(res)
                            if (res.code !== 0) {
                                ElMessage({type: 'error', message: "添加失败" + res.message});
                            } else {
                                this.$emit("nextStep", this.step + 1, this.productId);
                            }
                        }).catch(err => {
                        console.log(err);
                    })
                }
                this.form = [];
            },
            /**
             * 调用API前的数据检查
             *
             */
            _checkDataBeforeUpload() {
                this.isPrepare = true;
                //提交数据前进行判断
                this.params.forEach(item => {
                    //是否是多选框
                    if (item.isOnly === 1) {
                        if (item.chooseVals.length !== 0) {
                            this.form.push({
                                "attrId": item.id,
                                "attrVal": item.chooseVals,
                                "clothingId": this.productId
                            });
                        }
                    } else {
                        //判断是否对单选框进行了选择
                        if (!(item.availableVals instanceof Array)) {
                            this.form.push({
                                "attrId": item.id,
                                'attrVal': [item.availableVals],
                                "clothingId": this.productId
                            });
                        }
                    }
                });
            },
            /**
             *
             * 多选框选中回调
             */
            _checkChange(item, param, isChecked) {
                if (isChecked) {
                    param.chooseVals.push(item)
                } else {
                    param.chooseVals.remove(item)
                }
            },
            /**
             * 获取服装所有规格参数
             * @private
             */
            _getClothParams() {
                api.get("/clothing/attrs")
                    .then(res => {
                        console.log(res)
                        if (res.code === 0) {
                            res.data.records.forEach(item => {
                                item['availableVal'] = JSON.parse(item.availableVals);
                                item['availableVals'] = JSON.parse(item.availableVals);
                                if (item.isOnly === 1) {
                                    item.chooseVals = [];
                                }
                            });
                            //判断是否是编辑状态
                            if (this.stepTitle != '下一步')
                                this._getClothParam(this.productId, res.data.records);
                            else {
                                this.params = res.data.records;
                            }
                        }
                    }).catch(err => {
                    console.log(err)
                });
            }
            ,
            /**
             * 获取某件服装的属性参数
             * @private
             */
            _getClothParam(id, params) {
                api.get("/clothing/attr/vals", {params: {id: id}})
                    .then(res => {
                        let records = res.data.records;
                        if (res.code === 0) {
                            for (let idx in records) {
                                let record = records[idx];
                                //将之前已经选中的挑选出来
                                params.forEach(item => {
                                    if (record.attrId === item.id) {
                                        //多选
                                        if (item.isOnly === 1) {
                                            let attVals = JSON.parse(record.attrVal);
                                            item.chooseVals = JSON.parse(record.attrVal)
                                            item.availableVal.forEach((val, index) => {
                                                if (attVals.indexOf(val) !== -1) {
                                                    item.availableVals[index] = true;
                                                }
                                            })
                                        } else {
                                            item.availableVals = JSON.parse(record.attrVal)[0];
                                        }
                                    }
                                })
                            }
                            this.params = params;
                        }
                    }).catch(err => {
                    console.log(err)
                });
            }
        }
    };
</script>

<style>
    .el-form-item__label {
        font-weight: 700;
    }

    .el-radio-group {
        margin-right: 20px;
    }

    .el-form-item__content > div {
        overflow: hidden;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*flex-wrap: wrap;*/

    }
</style>

<template>

    <div class="form-box">
        <el-form ref="form" :model="form" label-width="160px">
            <el-form-item label="轮播图">
                <el-upload
                        :action="imageUploadUrl"
                        list-type="picture-card"
                        name="file"
                        :file-list="carouseImageUrls"
                        :show-file-list="true"
                        :on-success="handleCarouselAvatarSuccess"
                        :on-preview="handlePictureCardPreview"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="_handleCarouseRemove">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="详情图片">
                <el-upload
                        :action="imageUploadUrl"
                        list-type="picture-card"
                        name="file"
                        :file-list="detailsImageUrls"
                        :on-preview="handlePictureCardPreview"
                        :on-success="handleDetailsAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="_handleDetailsRemove">
                    <i class="el-icon-plus"></i>
                </el-upload>

            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">完成</el-button>
            </el-form-item>

        </el-form>
        <el-dialog v-model="dialogVisible">
            <img class="preview-img" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import api from '../api/api'
    import {IMAGE_UPLOAD_URL} from "../api/api";
    import {ElMessage} from 'element-plus';

    export default {
        name: 'ClothingImageUpload',
        props: {
            productId: {
                type: Number
            }
        },
        data() {
            return {
                dialogVisible: false,
                dialogImageUrl: '',
                imageUploadUrl: '',
                carouseImageUrls: [],
                detailsImageUrls: [],
            };
        },
        watch: {
            productId: function (newVal, oldVal) {
                console.log("productId:", newVal, oldVal)
                this._getClothPic(newVal);
            }
        },
        mounted() {
            this.imageUploadUrl = IMAGE_UPLOAD_URL;
            this._getClothPic(this.productId);
        },
        methods: {
            onSubmit() {
                let picData = [];
                if (this.carouseImageUrls.length === 0 || this.detailsImageUrls.length === 0) {
                    ElMessage({type: 'warning ', message: "请先上传图片"});
                    return;
                }
                this.carouseImageUrls.forEach((item, idx) => {
                    item['isBanner'] = 0;
                    item.picIndex = idx;
                    item.clothingId = this.productId;
                    picData.push(item);
                });
                this.detailsImageUrls.forEach((item, idx) => {
                    item['isBanner'] = 1;
                    item.picIndex = idx;
                    item.clothingId = this.productId;
                    picData.push(item);
                });

                api.post("/clothing/pics", picData)
                    .then(res => {
                        console.log(res);
                        if (res.code === 0) {
                            this.$message.success('提交成功！');
                            this.$emit("nextStep", 1);
                        }
                    }).catch(err => {
                    console.log(err);
                })
            },
            /**
             *
             * 上传轮播图片成功回调
             */
            handleCarouselAvatarSuccess(res, file) {
                if (res.code === 0) {
                    let data = res.data;
                    this.carouseImageUrls.push({
                        'uid': file.raw.uid,
                        'picUrl': data.fullPath,
                        'url': data.fullPath,
                        'picThumbnailUrl': data.thumbnail,
                    })
                } else {
                    ElMessage({type: 'error', message: res.message})
                }
            },
            /**
             *
             * 上传图片成功回调
             */
            handleDetailsAvatarSuccess(res, file) {
                if (res.code === 0) {
                    let data = res.data;
                    this.detailsImageUrls.push({
                        'uid': file.uid,
                        'picUrl': data.fullPath,
                        'url': data.fullPath,
                        'picThumbnailUrl': data.thumbnail
                    })
                } else {
                    ElMessage({type: 'error', message: res.message})
                }
            },
            /**
             * 删除轮播图回调
             * @param file
             * @param fileList
             */
            _handleCarouseRemove(file) {
                console.log("删除轮播图回调:", file);
                this.carouseImageUrls.forEach((item, idx) => {
                    if (item.uid === file.uid) {
                        this.carouseImageUrls.splice(idx, 1);
                    }
                })
                console.log(this.carouseImageUrls)
            },
            /**
             * 删除服装详情回调
             * @param file
             * @param fileList
             */
            _handleDetailsRemove(file, fileList) {
                console.log(file, fileList);
                this.detailsImageUrls.forEach((item, idx) => {
                    if (item.uid === file.uid) {
                        this.detailsImageUrls.splice(idx, 1);
                    }
                })
            },
            /**
             * 获取某服装的图片
             */
            _getClothPic(productId) {
                this.carouseImageUrls = [];
                this.detailsImageUrls = [];
                api.get("/clothing/pics", {
                    params: {
                        clothingId: productId
                    }
                })
                    .then(res => {
                        if (res.code === 0) {
                            let records = res.data.records;
                            records.forEach(item => {
                                if (item.isBanner === 0) {
                                    this.carouseImageUrls.push({
                                        'uid': item.id,
                                        'id': item.id,
                                        'picUrl': item.picUrl,
                                        'url': item.picUrl,
                                        'picThumbnailUrl': item.picThumbnailUrl
                                    })
                                } else {
                                    this.detailsImageUrls.push({
                                        'uid': item.id,
                                        'id': item.id,
                                        'picUrl': item.picUrl,
                                        'url': item.picUrl,
                                        'picThumbnailUrl': item.picThumbnailUrl
                                    })
                                }
                            })
                        }
                    }).catch(err => {
                    console.log(err);
                })
            },
            /**
             * 预览回调
             * @param file
             */
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            /**
             *  图片上传前回调
             * @param file
             * @returns {boolean}
             */
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            }
        }
    };
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload--text {
        width: 178px !important;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .preview-img {
        width: 100%;
        object-fit: cover;
    }
</style>

<template>

    <div class="form-box">
        <el-form ref="form" :model="form" label-width="160px">
            <el-form-item label="服装标题" :rules="[{ required: true, message: '服装标题不能为空'}]"
            >
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="副标题">
                <el-input v-model="form.subtitle"></el-input>
            </el-form-item>
            <el-form-item label="服装分类" :rules="[{ required: true, message: '服装分类不能为空'}]">
                <el-cascader :options="categories" v-model="chooseCategory" @change="_handleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="服装封面" :rules="[{ required: true, message: '服装封面不能为空'}]">
                <el-upload
                        class="avatar-uploader"
                        :action="imageUploadUrl"
                        :show-file-list="false"
                        name="file"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="_onNextStep">{{stepTitle}}</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
    import api from '../api/api'
    import {IMAGE_UPLOAD_URL} from "../api/api";
    import {ElMessage} from 'element-plus';

    export default {
        name: 'ClothingBaseEdit',
        props: {
            currentClothing: {
                type: Object, //指定传入的类型
                //type 也可以是一个自定义构造器函数，使用 instanceof 检测。
                default: () => ({
                    title: '',
                    subtitle: '',
                    coverUrl: '',
                    coverThumbnailUrl: '',
                })
            },
            stepTitle: {
                type: String,
                default: '下一步'
            }
        },
        data() {
            return {
                step: 1,
                imageUrl: '',
                imageUploadUrl: '',
                categories: [],
                chooseCategory: [],
                form: this.currentClothing
            };
        },
        mounted() {
            this._getClothingCategories();
            this.imageUploadUrl = IMAGE_UPLOAD_URL;
        },
        methods: {
            /**
             * 下一步
             */
            _onNextStep() {

                if (this.form.title.length === 0) {
                    ElMessage({type: 'error', message: '请输入服装标题'})
                    return;
                }
                if (!this.form.categoryId) {
                    ElMessage({type: 'error', message: '请选择服装分类'})
                    return;
                }
                //
                if (this.currentClothing.id) {
                    this.updateClothing();
                    console.log(this.currentClothing);

                } else {
                    api.post("/clothing", this.form)
                        .then(res => {
                            console.log(res)
                            if (res.code === 0) {
                                this.$emit("nextStep", this.step + 1, res.data);
                            } else {
                                ElMessage({type: 'error', message: res.message})
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }

            },
            /**
             *
             * 更新服装信息
             */
            updateClothing() {
                api.put("/clothing", this.form)
                    .then(res => {
                        console.log(res)
                        if (res.code === 0) {
                            this.$emit("nextStep", this.step + 1, res.data);
                        } else {
                            ElMessage({type: 'error', message: res.message})
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            /**
             *  服装分类级联菜单选中回调
             */
            _handleChange(e) {
                this.form.categoryId = e[e.length - 1]
            },
            /**
             * 获取服装分类信息
             * @private
             */
            _getClothingCategories() {
                api.get("/categories")
                    .then(res => {
                        if (res.code === 0) {
                            let data = res.data;
                            if (data.total === 0)
                                return;
                            this.categories = this._getTrees(data.records);
                            this._setChildrenNUll(this.categories);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

            /**
             *  删除空的children节点
             */
            _setChildrenNUll(data) {
                data.forEach(item => {
                    item['label'] = item.categoryName;
                    item['value'] = item.id;
                    if (item.children.length === 0) {
                        item.children = undefined;
                    } else {
                        this._setChildrenNUll(item.children);
                    }
                })
            },
            /**
             * 递归处理分类树结构
             * @param list
             * @param parentId
             * @returns {*}
             * @private
             */
            _getTrees(list, parentId = 0) {
                let parentObj = {};
                list.forEach(o => {
                    parentObj[o.id] = o;
                })
                if (!parentId) {
                    return list.filter(o => !parentObj[o.parentId]).map(o =>
                        (o.children = this._getTrees(list, o.id), o)
                    );
                } else {
                    return list.filter(o => o.parentId === parentId).map(o => (o.children = this._getTrees(list, o.id), o));
                }
            },
            /**
             *
             * 上传图片成功回调
             */
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                if (res.code === 0) {
                    let data = res.data;
                    this.form.coverUrl = data.fullPath;
                    this.form.coverThumbnailUrl = data.thumbnail;
                } else {
                    ElMessage({type: 'error', message: res.message})
                }
            },
            /**
             * 上传图片之前逻辑
             * @param file
             * @returns {boolean}
             */
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            }
        }
    };
</script>

<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload--text {
        width: 178px !important;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
